import { useState } from "react";
import { useTranslation } from "react-i18next";

const LangSwitch = () => {
  const { t, i18n } = useTranslation();
  const [languageMenuToggle, setLanguageMenuToggle] = useState(false);

  return (
    <section className="language-toggle" id="language-toggle">
      <div
        className="language-toggle__header"
        onClick={() => {
          setLanguageMenuToggle(!languageMenuToggle);
        }}
      >
        <p id="currentLangValue">{t("currentLanguage")}</p>
        <img
          width={24}
          height={24}
          style={{
            transform: languageMenuToggle ? "rotate(180deg)" : "",
          }}
          src="/images/svg/header-right/caret.svg"
          alt="caret icon"
        />
      </div>
      {languageMenuToggle ? (
        <div className="language-toggle__body">
          <a
            onClick={() => {
              i18n.changeLanguage("ru");
              setLanguageMenuToggle(false);
            }}
            className="language-toggle__body__item"
          >
            <p>RU</p>
          </a>
          <a
            onClick={() => {
              i18n.changeLanguage("en");
              setLanguageMenuToggle(false);
            }}
            className="language-toggle__body__item"
          >
            <p>EN</p>
          </a>
        </div>
      ) : (
        <></>
      )}
    </section>
  );
};

export default LangSwitch;