import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const HeaderLeftList = () => {
  const { t } = useTranslation();
  return (
    <div className="header__left__list">
      <Link to="/payments" className="header__left__list__item">
        <img
          src="/images/svg/header-left-menu/payments-icon.svg"
          width="16"
          height="12"
          alt="header left menu payments icon"
        />
        <p>{t("payments")}</p>
      </Link>
      {false && (
        <Link to="/vip-club" className="header__left__list__item">
          <img
            src="/images/svg/header-left-menu/vip-icon.svg"
            alt="header left menu vip icon"
          />
          <p>{t("vip_club")}</p>
        </Link>
      )}
      {false && (
        <Link to="/bonuses" className="header__left__list__item">
          <img
            src="/images/svg/header-left-menu/bonuses-icon.svg"
            width="15"
            height="14"
            alt="header left menu bonuses icon"
          />
          <p>{t("bonuses")}</p>
        </Link>
      )}
      <Link to="/faq" className="header__left__list__item">
        <img
          src="/images/svg/header-left-menu/faq-icon.svg"
          width="15"
          height="15"
          alt="header left menu faq icon"
        />
        <p>FAQ</p>
      </Link>
      <Link to="/terms" className="header__left__list__item">
        <img
          src="/images/svg/header-left-menu/policy-icon.svg"
          width="15"
          height="14"
          alt="header left menu terms icon"
        />
        <p>{t("terms")}</p>
      </Link>
      <Link to="/about" className="header__left__list__item">
        <img
          src="/images/svg/header-left-menu/about-icon.svg"
          width="14"
          height="14"
          alt="header left menu about icon"
        />
        <p>{t("about")}</p>
      </Link>
      <Link to="/policy" className="header__left__list__item">
        <img
          src="/images/svg/header-left-menu/policy-icon.svg"
          width="15"
          height="14"
          alt="header left menu policy icon"
        />
        <p>{t("privacy_policy")}</p>
      </Link>
    </div>
  );
};

export default HeaderLeftList;
