import { useAtom } from "jotai";
import { notificationsAtom } from "../App";
import { useMemo } from "react";

const NotificationsCounter = () => {
    const [notifications] = useAtom(notificationsAtom);

    const count = useMemo(
        () => notifications.filter((item) => !item.isViewed).length,
        [notifications],
    );

    // if (localStorage.getItem("accessToken") == null) {
    //     return;
    // }

    return <span className="notifications-counter">{count}</span>;
};

export default NotificationsCounter;
