import axios from "axios"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify"
import { formatItem } from "../api/Helper";

export default function GameItem ({auth,img,name,label,id,setActualGameLink,userId}) {
const navigate = useNavigate();
    const [t,i18n] = useTranslation();
    const openGame = () => {
        if (!auth) {
toast.error(t('need_login'));
            return;
        }

navigate('/game/'+id);

    }
    return (
        <a onClick={openGame}  data-gameId={id} className="games-list__item">
        <img src={img} loading="lazy"  alt={id} />
        <h4>{name}</h4>
        <span>{formatItem(label)}</span>
        {formatItem(label).toLowerCase().includes('pgsoft') && formatItem(label).toLowerCase() != "pgsoft sl" && (
            <div style={{
                position: 'absolute',
                bottom: '7px',
                right: '10px',
                fontSize: '12px',
            }} className="vpn">VPN</div>
        )}
       
      </a>
    )
}