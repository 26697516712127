import { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ClipLoader from "react-spinners/ClipLoader";
import {
    authAtom,
    avatarAtom,
    balanceAtom,
    depositOpenAtom,
    idAtom,
    isFullScreen,
    isTelegramAtom,
    logInOpenAtom,
    needFinishReg,
    notificationsAtom,
    payDataAtom,
    payDataTimeAtom,
    socketAtom,
    telegramUsernameAtom,
    usernameAtom,
} from "../App";
import { io } from "socket.io-client";
import { useAtom } from "jotai";

import { IMaskInput } from "react-imask";
import LangSwitch from "./header/LangSwitch";
import HeaderLeftList from "./header/HeaderLeftList";
import HeaderRightList from "./header/HeaderRightList";
import HeaderMobileProfileRight from "./header/HeaderMobileProfileRight";
import ClipboardCopyIcon from "./header/ClipboardCopyButton";
import NotificationsCounter from "./NotificationsCounter";

const Header = () => {
    const [finishRegData, setFinishRegData] = useState({
        password: "",
        confirm_password: "",
        email: "",
        phone: "",
    });
    const [finishRegistrationOpen, setFinishRegistrationOpen] = useState(false);
    const [isTelegram] = useAtom(isTelegramAtom);
    const [notifications, setNotifications] = useAtom(notificationsAtom);
    const [senderName, setSenderName] = useState("");

    const [payDataTime, setPayDataTime] = useAtom(payDataTimeAtom);
    const [fullScreen, setFullScreen] = useAtom(isFullScreen);
    function format(data) {
        var sec_num = parseInt(data, 10); // don't forget the second param
        var hours = Math.floor(sec_num / 3600);
        var minutes = Math.floor((sec_num - hours * 3600) / 60);
        var seconds = sec_num - hours * 3600 - minutes * 60;
        if (hours < 10) {
            hours = "0" + hours;
        }
        if (minutes < 10) {
            minutes = "0" + minutes;
        }
        if (seconds < 10) {
            seconds = "0" + seconds;
        }
        return minutes + ":" + seconds;
    }
    const [avatar] = useAtom(avatarAtom);
    const [sendPopupOpen, setSendPopupOpen] = useState(false);
    const [sendFormSum, setSendFormSum] = useState();
    const [depositSum, setDepositSum] = useState("");
    const [sendFormLogin, setSendFormLogin] = useState();

    const [depMethod, setDepMethod] = useState("card");
    const [username, setUsername] = useAtom(usernameAtom);
    const [id, setId] = useAtom(idAtom);
    const [params, setParams] = useSearchParams();
    const [unmaskedLogin, setUnmaskedLogin] = useState();
    const [auth, setAuth] = useAtom(authAtom);
    const inputRef = useRef();
    const [restoreEmail, setRestoreEmail] = useState("");
    const [balance, setBalance] = useAtom(balanceAtom);
    const navigate = useNavigate();
    const [payData, setPayData] = useAtom(payDataAtom);
    const [telegramUsername] = useAtom(telegramUsernameAtom);
    const [restorePassword, setRestorePassword] = useState("");
    const [restoreCode, setRestoreCode] = useState("");
    const maskOptions = [
        {
            mask: "+{7}(000)000-00-00",
        },
        {
            mask: /^\S*@?\S*$/,
        },
    ];
    const [loginRegInput, setLoginRegInput] = useState("");
    const [loginRegConfirmInput, setLoginRegConfirmInput] = useState("");
    const [registerFormPassword, setRegisterFormPassword] = useState("");
    const [registerMethodData, setRegisterMethodData] = useState("");
    const [loginFormLogin, setLoginFormLogin] = useState("");
    const [code, setCode] = useState();
    const [page, setPage] = useState(1);
    const [socket, setSocket] = useAtom(socketAtom);

    const loadSocket = () => {
        var d = io.connect(process.env.REACT_APP_SOCKET, {
            auth: {
                token: localStorage.getItem("accessToken"),
            },
            secure: true,
        });
        d.emit("join", (id) => id);
        d.on("payment.bank.success", (data) => {
            if (!payData.id == data.transaction_id) {
                return;
            }

            setPayData((payData) => ({
                ...payData,
                open: true,
                loading: "success",
            }));
            setTimeout(() => {
                setPayData((payData) => ({ open: false }));
            }, 4000);
        });

        d.on("payment.bank.cancelled", (data) => {
            if (!payData.id == data.transaction_id) {
                return;
            }

            setPayData((payData) => ({
                ...payData,
                open: true,
                loading: "cancel",
            }));
            setTimeout(() => {
                setPayData({ open: false });
            }, 4000);
        });
        d.on("balanceUpdated", (data) => {
            setBalance({ ...balance, RUB: data.balance });
        });
        setSocket(d);
    };
    const [loginFormPassword, setLoginFormPassword] = useState("");
    const [bonuses, setBonuses] = useState([]);

    const SendNewPassword = () => {
        var data = {
            code: restoreCode,
            password: restorePassword,
        };
        setLogInOpen(false);
        axios
            .post(process.env.REACT_APP_API + "/auth/restore", data)
            .then((response) => {
                if (response.data.status === "success")
                    toast.success(t("password_changed"));
            })
            .catch((error) => {
                toast.error(t("incorrect_restore_link"));
            });
    };

    const SendCode = () => {
        var data = {
            email: loginFormLogin,
        };
        axios
            .post(process.env.REACT_APP_API + "/auth/send-code", data)
            .catch((error) => {
                toast.error(t("email_not_registered"));
            })
            .then((response) => {
                toast.success(t("code_was_sent_email"));
                setLogInOpen(false);
            });
    };
    const LoadAuthData = () => {
        axios
            .get(process.env.REACT_APP_API + "/user/me", {
                headers: {
                    Authorization:
                        "Bearer " + localStorage.getItem("accessToken"),
                },
            })
            .then((response) => {
                setUsername(response.data.user.name);
                setId(response.data.user.id);
                setNotifications(response.data.notifications[0]);
                loadSocket();
                setBalance({ RUB: response.data.user.balance, USD: 0, EUR: 0 });
                setAuth(true);
            })
            .catch((error) => {});
    };
    useEffect(() => {
        if (payDataTime <= 0) {
            setPayData({ open: false });
        }
    }, [payDataTime]);
    useEffect(() => {
        var interval = setInterval(() => {
            setPayDataTime((payDataTime) => Math.max(payDataTime - 1, 0));
        }, 1000);
        if (params.get("restoreCode") !== null) {
            setRestoreCode(params.get("restoreCode"));
            navigate("/");
            setRestorePassword("");

            setLogInOpen(true);
            setTimeout(() => {
                setPage(5);
            }, 10);
        }

        document.body.addEventListener("click", function () {
            setRightMenuToggle(false);
            setOpen(false);
        });
        return () => {
            clearInterval(interval);
        };
    }, []);
    const regApiCall = () => {
        var data = {
            email: loginFormLogin,
            password: loginRegInput,
        };
        if (localStorage.getItem("referralId")) {
            data.user_referral_id = localStorage.getItem("referralId");
            localStorage.removeItem("referralId");
        }
        if (localStorage.getItem("referralInvitationId")) {
            data.referral_invitation_id = localStorage.getItem(
                "referralInvitationId",
            );
            localStorage.removeItem("referralInvitationId");
        }
        if (loginRegInput != loginRegConfirmInput) {
            toast.error(t("passwords_not_match"));
            return;
        }

        axios
            .post(process.env.REACT_APP_API + "/auth/sign", data)
            .then((response) => {
                if (response.status !== 200 && response.status !== 201) {
                    return;
                }

                setLogInOpen(false);
                localStorage.setItem(
                    "accessToken",
                    response.data.tokens.accessToken,
                );
                document.cookie =
                    "refreshToken=" + response.data.tokens.refreshToken;
                LoadAuthData();
            })
            .catch((error) => {
                toast.error(t("invalid_password"));
            });
    };
    const loginApiCall = () => {
        var unmaskedLogin = loginFormLogin;
        if (!unmaskedLogin.includes("@"))
            unmaskedLogin = unmaskedLogin
                .replace("(", "")
                .replace(")", "")
                .replace("-", "")
                .replace("-", "");

        var isPhone = true;
        if (
            String(unmaskedLogin)
                .toLowerCase()
                .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                )
        )
            isPhone = false;
        else {
            if (
                !/^\d+$/.test(unmaskedLogin.trim().replace("+", "")) ||
                unmaskedLogin.length < 9
            ) {
                toast.error(t("validation_error"));
                return;
            }
            if (!unmaskedLogin.startsWith("+"))
                setUnmaskedLogin((unmaskedLogin) => "+" + unmaskedLogin);
        }

        var data = {
            email: unmaskedLogin,
            password: loginFormPassword,
        };
        if (isPhone) {
            setUnmaskedLogin(unmaskedLogin);
            data = {
                phone: unmaskedLogin,
            };
            if (
                localStorage.getItem("referralId") != null &&
                /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(
                    localStorage.getItem("referralId"),
                )
            ) {
                data.user_referral_id = localStorage.getItem("referralId");
                localStorage.removeItem("referralId");
            }
            if (
                localStorage.getItem("referralInvitationId") != null &&
                /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(
                    localStorage.getItem("referralInvitationId"),
                )
            ) {
                data.referral_invitation_id = localStorage.getItem(
                    "referralInvitationId",
                );
                localStorage.removeItem("referralInvitationId");
            }
            axios
                .post(process.env.REACT_APP_API + "/auth/sign", data)
                .then((response) => {
                    if (response.status !== 200 && response.status !== 201) {
                        return;
                    }
                    toast.info(t("code_was_sent"));
                    setPage(3);
                })
                .catch((error) => {
                    toast.error(t("invalid_password"));
                });
            return;
        }

        if (page == 2) {
            axios
                .post(process.env.REACT_APP_API + "/auth/sign", data)
                .then((response) => {
                    if (response.status !== 200 && response.status !== 201) {
                        return;
                    }

                    setLogInOpen(false);
                    localStorage.setItem(
                        "accessToken",
                        response.data.tokens.accessToken,
                    );
                    document.cookie =
                        "refreshToken=" + response.data.tokens.refreshToken;
                    LoadAuthData();
                })
                .catch((error) => {
                    toast.error(t("invalid_password"));
                });
        } else {
            axios
                .post(process.env.REACT_APP_API + "/auth/check", data)
                .then((response) => {
                    if (response.data == true) {
                        setPage(2);
                    } else if (response.data == false) {
                        setPage(6);
                    }
                })
                .catch((error) => {});
        }
    };
    const codeApiCall = () => {
        var data = {
            phone: unmaskedLogin,
            code: Number(code),
        };
        if (localStorage.getItem("referralId") != null) {
            data.user_referral_id = localStorage.getItem("referralId");
        }
        if (localStorage.getItem("referralInvitationId") != null) {
            data.referral_invitation_id = localStorage.getItem(
                "referralInvitationId",
            );
        }

        axios
            .post(process.env.REACT_APP_API + "/auth/checkCode", data)
            .catch((error) => {
                toast.error(t("code_is_incorrect"));
            })
            .then((response) => {
                setCode("");
                setLogInOpen(false);
                localStorage.setItem("accessToken", response.data.accessToken);
                LoadAuthData();
            });
    };
    const registerApiCall = () => {
        var data = {
            password: registerFormPassword,
        };

        switch (regType) {
            case t("phone"):
                data["phone"] = registerMethodData;
                break;
            case t("email"):
                data["email"] = registerMethodData;
                break;
        }

        axios
            .post(process.env.REACT_APP_API + "/auth/register", data)
            .then((response) => {
                if (response.status !== 201) {
                    toast.error(response.data.error);
                    return;
                } else {
                    setAuth(true);
                    setRegisterOpen(false);
                    localStorage.setItem(
                        "accessToken",
                        response.data.accessToken,
                    );
                }
            })
            .catch((error) => {});
    };

    const [selectedBonus, setSelectedBonus] = useState(0);
    const [selectedCurrency, setSelectedCurrency] = useState("RUB");
    const { t, i18n } = useTranslation();

    const [depositOpen, setDepositOpen] = useAtom(depositOpenAtom);
    const [registerOpen, setRegisterOpen] = useState(false);
    const [logInOpen, setLogInOpen] = useAtom(logInOpenAtom);
    useEffect(() => {
        if (logInOpen == false) {
            setPage(1);
            setLoginFormLogin("");
            setCode("");
            setLoginFormPassword("");
        }
    }, [logInOpen]);
    const [rightMenuToggle, setRightMenuToggle] = useState(false);
    const [regType, setRegType] = useState(t("phone"));

    const [needFill, setNeedfill] = useAtom(needFinishReg);
    const [open, setOpen] = useState(false);

    return (
        <>
            <header className={`header ${fullScreen && 'full-screen'}`}>
                <div className="header__left">
                    <a
                        onClick={(e) => {
                            e.stopPropagation();
                            setOpen(!open);
                        }}
                        className="header__left__burger-toggle"
                    >
                        {!open ? (
                            <img
                                src="/images/svg/burger.svg"
                                alt="menu-bruger-icon"
                            />
                        ) : (
                            <>
                                <img
                                    src="/images/svg/cross.svg"
                                    alt="menu-bruger-icon"
                                />
                                <HeaderLeftList />
                            </>
                        )}
                    </a>
                    <Link to="/" className="logo header__left__logo">
                        <img src="/images/color_logo.svg" alt="" />
                    </Link>
                </div>
                <div className="header__right">
                    <a
                        href="https://t.me/lotoscasino"
                        target="_blank"
                        className="hml media-link header__right__media-link"
                    >
                        <img
                            src="/images/svg/header-right/telegram.svg"
                            alt="telegram link icon"
                        />
                    </a>
                    <LangSwitch />
                    {auth ? (
                        <>
                            <Link
                                to="/profile/notifications"
                                className="notifications-toggle"
                            >
                                <img
                                    width={16}
                                    height={16}
                                    src="/images/svg/header-right/notifications-toggle.svg"
                                    alt="notifications toggle icon"
                                />
                                <NotificationsCounter />
                            </Link>
                            <div className="selector white-selector">
                                <div
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.target.parentElement.classList.toggle(
                                            "open",
                                        );
                                    }}
                                    className="selector__header"
                                >
                                    <p className="selector__value">
                                        <img
                                            width={16}
                                            height={16}
                                            src="/images/svg/header-right/currency-rub-icon.svg"
                                            alt="currency rub icon"
                                        />
                                        {balance.RUB.toFixed(2).toLocaleString(
                                            "en-US",
                                        )}
                                    </p>
                                    <img
                                        width={17}
                                        height={17}
                                        src="/images/svg/header-right/currency-select-caret.svg"
                                        alt="currency select caret icon"
                                    />
                                </div>

                                <div
                                    onClick={(e) => {
                                        if (e.target.nodeName == "A") {
                                            e.target.parentElement.parentElement.parentElement.classList.remove(
                                                "open",
                                            );
                                            return;
                                        }
                                        if (
                                            e.target.classList.contains(
                                                "selector__body",
                                            )
                                        )
                                            return;
                                        e.target.parentElement.parentElement.classList.remove(
                                            "open",
                                        );

                                        if (!e.target.nextSibling) return;
                                        e.target.parentElement.previousSibling.querySelector(
                                            "p",
                                        ).innerHTML = e.target.innerHTML;
                                    }}
                                    className="selector__body"
                                >
                                    <div>
                                        <img
                                            width={16}
                                            height={16}
                                            src="/images/svg/header-right/currency-rub-icon.svg"
                                            alt="currency rub icon"
                                        />
                                        {balance.RUB.toFixed(2).toLocaleString(
                                            "en-US",
                                        )}
                                    </div>
                                </div>
                            </div>
                            <a
                                onClick={() => {
                                    setDepositOpen(true);
                                }}
                                className="btn"
                            >
                                {t("deposit")}
                            </a>
                            <div
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    document
                                        .querySelector(".Mobile-ProfileRight")
                                        .classList.add("open");
                                    setRightMenuToggle(!rightMenuToggle);
                                }}
                                className="user-block"
                            >
                                <p>{telegramUsername}</p>
                                <img src={avatar} />
                                <div>
                                    <p>{username}</p>
                                    <span>{1 + " LVL"}</span>
                                </div>
                                <img
                                    style={{
                                        transition: "transform 0.2s",
                                        transform: rightMenuToggle
                                            ? "rotate(180deg)"
                                            : "",
                                        width: 17,
                                        height: 17,
                                    }}
                                    src="/images/svg/header-right/currency-select-caret.svg"
                                    alt="currency select caret icon"
                                />
                            </div>
                            {rightMenuToggle && (
                                <HeaderRightList
                                    onRightMenuClose={() => {
                                        setRightMenuToggle(false);
                                    }}
                                />
                            )}
                        </>
                    ) : (
                        <>
                            <a
                                onClick={() => {
                                    setLoginFormLogin("");
                                    setCode("");
                                    setLoginFormPassword("");
                                    setPage(1);
                                    setLogInOpen(true);
                                }}
                                className="white-btn"
                            >
                                {t("log_in")}
                            </a>
                            <a
                                onClick={() => {
                                    setLoginFormLogin("");
                                    setLoginFormPassword("");
                                    setPage(1);
                                    setLogInOpen(true);
                                    setCode("");
                                }}
                                className="btn"
                            >
                                {t("register")}
                            </a>
                        </>
                    )}
                </div>
            </header>
            {location.pathname == "/" && needFill == true && isTelegram && (
                <div className="finish-registration">
                    <div className="finish-registration__container">
                        <div>
                            <svg
                                width="20"
                                height="20"
                                viewBox="-0.5 0 25 25"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M10.8809 16.15C10.8809 16.0021 10.9101 15.8556 10.967 15.7191C11.024 15.5825 11.1073 15.4586 11.2124 15.3545C11.3175 15.2504 11.4422 15.1681 11.5792 15.1124C11.7163 15.0567 11.8629 15.0287 12.0109 15.03C12.2291 15.034 12.4413 15.1021 12.621 15.226C12.8006 15.3499 12.9399 15.5241 13.0211 15.7266C13.1024 15.9292 13.122 16.1512 13.0778 16.3649C13.0335 16.5786 12.9272 16.7745 12.7722 16.9282C12.6172 17.0818 12.4204 17.1863 12.2063 17.2287C11.9922 17.2711 11.7703 17.2494 11.5685 17.1663C11.3666 17.0833 11.1938 16.9426 11.0715 16.7618C10.9492 16.5811 10.8829 16.3683 10.8809 16.15ZM11.2408 13.42L11.1008 8.20001C11.0875 8.07453 11.1008 7.94766 11.1398 7.82764C11.1787 7.70761 11.2424 7.5971 11.3268 7.5033C11.4112 7.40949 11.5144 7.33449 11.6296 7.28314C11.7449 7.2318 11.8697 7.20526 11.9958 7.20526C12.122 7.20526 12.2468 7.2318 12.3621 7.28314C12.4773 7.33449 12.5805 7.40949 12.6649 7.5033C12.7493 7.5971 12.813 7.70761 12.8519 7.82764C12.8909 7.94766 12.9042 8.07453 12.8909 8.20001L12.7609 13.42C12.7609 13.6215 12.6809 13.8149 12.5383 13.9574C12.3958 14.0999 12.2024 14.18 12.0009 14.18C11.7993 14.18 11.606 14.0999 11.4635 13.9574C11.321 13.8149 11.2408 13.6215 11.2408 13.42Z"
                                    fill="white"
                                />
                                <path
                                    d="M12 21.5C17.1086 21.5 21.25 17.3586 21.25 12.25C21.25 7.14137 17.1086 3 12 3C6.89137 3 2.75 7.14137 2.75 12.25C2.75 17.3586 6.89137 21.5 12 21.5Z"
                                    stroke="white"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                            <p>Завершите регистрацию</p>
                        </div>
                        <a
                            onClick={() => {
                                setFinishRegistrationOpen(true);
                            }}
                        >
                            Завершить
                        </a>
                    </div>
                </div>
            )}
            <HeaderMobileProfileRight
                onRightMenuClose={() => {
                    setRightMenuToggle(false);
                }}
            />
            <div
                onClick={() => {
                    setDepositOpen(false);
                }}
                className={
                    depositOpen
                        ? "deposit-popup popup--fs open"
                        : "deposit-popup popup--fs"
                }
            >
                <div
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                    className="deposit-popup__form"
                >
                    <svg
                        className="close-svg"
                        onClick={() => {
                            setDepositOpen(false);
                        }}
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M17 7L7 17M7 7L17 17"
                            stroke="white"
                            stroke-opacity="0.8"
                            strokeWidth="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>

                    <h1>{t("deposit")}</h1>
                    <div>
                        <nav>
                            <p>Пополнить кошелек</p>
                            <div className="selector">
                                <div
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.target.parentElement.classList.toggle(
                                            "open",
                                        );
                                    }}
                                    className="selector__header"
                                >
                                    <p className="selector__value">
                                        <img
                                            width={16}
                                            height={16}
                                            src="/images/svg/header-right/currency-rub-icon.svg"
                                            alt="currency rub icon"
                                        />
                                        RUB
                                    </p>
                                    <img
                                        width={16}
                                        height={16}
                                        src="/images/svg/header-right/currency-select-caret-blue.svg"
                                        alt="currency select caret blue icon"
                                    />
                                </div>
                                <div
                                    onClick={(e) => {
                                        if (
                                            e.target.classList.contains(
                                                "selector__body",
                                            )
                                        )
                                            return;
                                        e.target.parentElement.previousSibling.querySelector(
                                            "p",
                                        ).innerHTML = e.target.innerHTML;
                                        e.target.parentElement.parentElement.classList.remove(
                                            "open",
                                        );
                                        setSelectedCurrency(
                                            e.target.textContent,
                                        );
                                    }}
                                    className="selector__body"
                                >
                                    <div>
                                        <img
                                            width={16}
                                            height={16}
                                            src="/images/svg/header-right/currency-rub-icon.svg"
                                            alt="currency rub icon"
                                        />
                                        RUB
                                    </div>
                                    <div>
                                        <img
                                            width={16}
                                            height={16}
                                            src="/images/svg/header-right/currency-eur-icon.svg"
                                            alt="currency eur icon"
                                        />
                                        EUR
                                    </div>
                                    <div>
                                        <img
                                            width={16}
                                            height={16}
                                            src="/images/svg/header-right/currency-usd-icon.svg"
                                            alt="currency usd icon"
                                        />
                                        USD
                                    </div>
                                </div>
                            </div>
                            <p>Метод пополнения</p>
                            <div className="left-right-select y">
                                <div
                                    onClick={(e) => {
                                        e.target.parentElement
                                            .querySelector(".selected")
                                            .classList.remove("selected");
                                        e.target.classList.add("selected");
                                        setDepMethod("card");
                                    }}
                                    className="selected"
                                >
                                    <div>
                                        <img
                                            src="/images/sber_png.png"
                                            alt=""
                                        />
                                        <img
                                            src="/images/svg/header-right/alpha-bank-icon.svg"
                                            alt="alpha bank icon"
                                        />
                                        <img src="/images/tbank.png" alt="" />
                                    </div>
                                    <p>Карта</p>
                                </div>
                                <div
                                    onClick={(e) => {
                                        e.target.parentElement
                                            .querySelector(".selected")
                                            .classList.remove("selected");
                                        e.target.classList.add("selected");
                                        setDepMethod("sbp");
                                    }}
                                >
                                    <div>
                                        <img
                                            src="/images/svg/header-right/sbp-icon.svg"
                                            alt="sbp icon"
                                        />
                                    </div>
                                    <p>СБП</p>
                                </div>
                            </div>
                            {depMethod != "crypto" && (
                                <>
                                    {" "}
                                    <p>Имя отправителя</p>
                                    <input
                                        value={senderName}
                                        onChange={(e) => {
                                            setSenderName(e.target.value);
                                        }}
                                        type="text"
                                        placeholder="Имя отправителя"
                                    />
                                </>
                            )}
                            <p>Сумма, {selectedCurrency}</p>
                            <input
                                value={depositSum}
                                onChange={(e) => {
                                    setDepositSum(e.target.value);
                                }}
                                type="number"
                                placeholder="Сумма"
                            />

                            {false && (
                                <>
                                    <div
                                        onClick={(e) => {
                                            if (
                                                e.target.classList.contains(
                                                    "deposit-bonus-select",
                                                )
                                            )
                                                return;
                                            e.target.parentElement
                                                .querySelector(".selected")
                                                .classList.remove("selected");
                                            e.target.classList.add("selected");

                                            var index = 1;
                                            var children =
                                                    e.target.parentNode
                                                        .childNodes,
                                                i = 0;
                                            for (; i < children.length; i++) {
                                                if (children[i] == e.target) {
                                                    index = i;
                                                }
                                            }

                                            setSelectedBonus(index);
                                        }}
                                        className="deposit-bonus-select"
                                    >
                                        <div className="selected">
                                            <img
                                                src="/images/svg/header-right/deposit-bonus-icon.svg"
                                                alt="deposit bonus icon"
                                            />
                                        </div>
                                        <div>
                                            <img
                                                src="/images/svg/header-right/deposit-bonus-icon.svg"
                                                alt="deposit bonus icon"
                                            />
                                        </div>
                                        <div>
                                            <img
                                                src="/images/svg/header-right/deposit-bonus-cross.svg"
                                                alt="deposit bonus cross icon"
                                            />
                                        </div>
                                    </div>
                                    <span>
                                        {selectedBonus != bonuses.length
                                            ? bonuses[selectedBonus].name
                                            : "Без бонуса"}
                                    </span>
                                </>
                            )}

                            <p>Использовать промокод</p>
                            <input type="text" placeholder="Промокод" />
                            <a
                                onClick={() => {
                                    if (auth) {
                                        if (depositSum < 300) {
                                            toast.error(
                                                "Минимальная сумма пополнения - 300 руб",
                                            );
                                            return;
                                        }
                                        if (
                                            senderName.length < 2 &&
                                            depMethod != "crypto"
                                        ) {
                                            toast.error(
                                                "Введите имя отправителя!",
                                            );
                                            return;
                                        }
                                        if (depMethod === "crypto") {
                                            axios
                                                .post(
                                                    process.env.REACT_APP_API +
                                                        "/payment/crypto",
                                                    {
                                                        method: "string",
                                                        amount: Number(
                                                            depositSum,
                                                        ),
                                                    },
                                                    {
                                                        headers: {
                                                            Authorization:
                                                                "Bearer " +
                                                                localStorage.getItem(
                                                                    "accessToken",
                                                                ),
                                                        },
                                                    },
                                                )
                                                .then((response) => {
                                                    if (
                                                        response.data.invoice
                                                            .status ===
                                                        "success"
                                                    ) {
                                                        if (
                                                            response.data
                                                                .invoice.result
                                                                .link != null
                                                        ) {
                                                            window.open(
                                                                response.data
                                                                    .invoice
                                                                    .result
                                                                    .link,
                                                            );
                                                        }
                                                    }
                                                })
                                                .catch((error) => {});
                                        } else {
                                            var method = depMethod;

                                            axios
                                                .get(
                                                    process.env.REACT_APP_API +
                                                        "/payment/details?method=" +
                                                        method,

                          {
                            headers: {
                              Authorization:
                                "Bearer " + localStorage.getItem("accessToken"),
                            },
                          }
                        )
                        .then((responseFirst) => {
                          axios
                            .post(
                              process.env.REACT_APP_API + "/payment/bank",
                              {
                                sender_name: senderName,
                                amount: Number(depositSum),
                                payment_method: depMethod,
                              },
                              {
                                headers: {
                                  Authorization:
                                    "Bearer " +
                                    localStorage.getItem("accessToken"),
                                },
                              }
                            )
                            .then((response) => {
                              var detalis =
                                response.data.invoice.payment_details;
                              if (detalis != null) {
                                var credentials = null;
                                var isSbp = false;
                                var bank = detalis.bank;
                                if (method === "sbp") {
                                  credentials = detalis.data;
                                  bank = detalis.bank;
                                  isSbp = true;
                                } else {
                                  credentials = detalis.data;
                                }
                                var payDataNew = {
                                  open: true,
                                  credentials: credentials,
                                  bank: bank,
                                  amount: response.data.invoice.amount,
                                  id: response.data.transaction.id,
                                  isSbp: isSbp,
                                  recipient_name: detalis.recipient_name,
                                };
                                console.log(payDataNew);
                                setDepositOpen(false);
                                setPayDataTime(600);
                                setPayData(payDataNew);
                              }
                            })
                            .catch((error) => {});
                        })
                        .catch((error) => {});
                    }
                  }
                }}
                className="btn"
              >
                Оплатить
              </a>
            </nav>
          </div>
        </div>
      </div>
      <div
        onClick={() => {
          setPayData({ ...payData, open: false });
        }}
        className={"depositPopup popup--fs " + (payData.open && " open")}
      >
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
          className="depositPopup__form"
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              justifyContent: "space-between",
              marginBottom: "24px",
            }}
          >
            <h1>Перевод</h1>
            <p>{format(payDataTime)}</p>
          </div>
          <div>
            <nav>
              {payData.isSbp ? (
                <>
                  <div>
                    {" "}
                    <p>Переведите на</p>{" "}
                  </div>
                  <div className="f50">
                    <div className="stroke-b-img">
                      <p style={{ opacity: ".8" }}>Банк получателя</p>
                      <span>{payData.bank}</span>
                    </div>
                    <div className="stroke-b-img">
                      <p style={{ opacity: ".8" }}>Имя получателя</p>
                      <span>{payData.recipient_name}</span>
                    </div>
                  </div>

                                    <div className="copy-input">
                                        <input
                                            style={{
                                                maxWidth: "100%",
                                                width: "100%",
                                            }}
                                            type="text"
                                            value={payData.credentials}
                                            disabled
                                        />
                                        {/* here! */}
                                        <ClipboardCopyIcon
                                            copyValue={payData.credentials}
                                            message={"Реквизиты скопированы"}
                                        />
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="card-in">
                                        {payData.bank == "Sber" && (
                                            <img src="https://lotos.na4u.ru/sber_big.png" />
                                        )}
                                        <div
                                            className="copy-input"
                                            style={{ width: "100%" }}
                                        >
                                            <span>{payData.credentials}</span>
                                            <ClipboardCopyIcon
                                                copyValue={payData.credentials}
                                                message={
                                                    "Номер карты скопирован"
                                                }
                                            />
                                        </div>
                                        <div
                                            className="copy-input"
                                            style={{ width: "100%" }}
                                        >
                                            <span>
                                                {payData.recipient_name}
                                            </span>
                                            <ClipboardCopyIcon
                                                copyValue={
                                                    payData.recipient_name
                                                }
                                                message={
                                                    "Имя получателя скопировано"
                                                }
                                            />
                                        </div>
                                    </div>
                                </>
                            )}

                            <p>Сумма</p>
                            <div className="copy-input">
                                <input
                                    style={{ maxWidth: "100%", width: "100%" }}
                                    type="text"
                                    value={
                                        payData.amount &&
                                        payData.amount.toFixed(2) + " RUB"
                                    }
                                    disabled
                                />
                                <ClipboardCopyIcon
                                    copyValue={payData?.amount?.toFixed(2)}
                                    message={"Сумма скопирована"}
                                />
                            </div>
                            {payData.loading == "waiting" ? (
                                <>
                                    <div style={{ textAlign: "center" }}>
                                        <ClipLoader color="white" size={24} />
                                        <p>Ожидание подтверждения платежа</p>
                                    </div>
                                </>
                            ) : payData.loading === "success" ? (
                                <>
                                    <div style={{ textAlign: "center" }}>
                                        <svg
                                            width="24px"
                                            height="24px"
                                            viewBox="0 0 1024 1024"
                                            class="icon"
                                            version="1.1"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M866.133333 258.133333L362.666667 761.6l-204.8-204.8L98.133333 618.666667 362.666667 881.066667l563.2-563.2z"
                                                fill="#43A047"
                                            />
                                        </svg>
                                        <p>Платёж подтверждён</p>
                                    </div>
                                </>
                            ) : payData.loading === "cancel" ? (
                                <>
                                    <div style={{ textAlign: "center" }}>
                                        <svg
                                            fill="#DF7373"
                                            width="24px"
                                            height="24px"
                                            viewBox="0 0 16 16"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M0 14.545L1.455 16 8 9.455 14.545 16 16 14.545 9.455 8 16 1.455 14.545 0 8 6.545 1.455 0 0 1.455 6.545 8z"
                                                fillRule="evenodd"
                                            />
                                        </svg>
                                        <p>Платёж отклонен</p>
                                    </div>
                                </>
                            ) : (
                                <>
                                    {payData.isSbp && (
                                        <>
                                            {" "}
                                            <p
                                                style={{
                                                    opacity: 0.9,
                                                    marginBottom: 8,
                                                }}
                                            >
                                                Внимание! Если вы отправите
                                                деньги не в тот банк, мы не
                                                сможем их вернуть!
                                            </p>{" "}
                                        </>
                                    )}
                                    <a
                                        onClick={() => {
                                            axios
                                                .post(
                                                    process.env.REACT_APP_API +
                                                        "/payment/confirm-transaction-as-user",
                                                    {
                                                        transaction_id:
                                                            payData.id,
                                                    },
                                                    {
                                                        headers: {
                                                            Authorization:
                                                                "Bearer " +
                                                                localStorage.getItem(
                                                                    "accessToken",
                                                                ),
                                                        },
                                                    },
                                                )
                                                .then((response) => {
                                                    if (response.data) {
                                                        setPayData({
                                                            ...payData,
                                                            loading: "waiting",
                                                        });
                                                    }
                                                })
                                                .catch((error) => {
                                                    if (error.status == 403)
                                                        toast.error(
                                                            "Платеж не найден, подождите несколько секунд и попробуйте снова!",
                                                        );
                                                });
                                        }}
                                        className="btn"
                                    >
                                        Я оплатил
                                    </a>
                                    <a
                                        onClick={() => {
                                            setPayData({ open: false });
                                        }}
                                        className="btn"
                                    >
                                        Отмена
                                    </a>
                                </>
                            )}
                        </nav>
                    </div>
                </div>
            </div>
            <div
                onClick={() => {
                    setSendPopupOpen(false);
                }}
                className={
                    sendPopupOpen
                        ? "send-popup popup--fs open"
                        : "send-popup popup--fs"
                }
            >
                <div
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                    className="send-popup__form"
                >
                    <svg
                        className="close-svg"
                        onClick={() => {
                            setSendPopupOpen(false);
                        }}
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M17 7L7 17M7 7L17 17"
                            stroke="white"
                            stroke-opacity="0.8"
                            strokeWidth="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                    <h1>{t("send_balance")}</h1>
                    <div>
                        <nav>
                            <p>
                                {t("email")} / {t("phone")}
                            </p>
                            <IMaskInput
                                type="text"
                                mask={maskOptions}
                                onAccept={(value, mask) =>
                                    setSendFormLogin(value)
                                }
                                placeholder={t("insert_email_or_phone")}
                            ></IMaskInput>
                            <p>{t("amount")}</p>
                            <input
                                type="number"
                                placeholder={t("amount")}
                                value={sendFormSum}
                                onChange={(e) => {
                                    setSendFormSum(e.target.value);
                                }}
                            />
                            <a
                                onClick={() => {
                                    axios
                                        .post(
                                            process.env.REACT_APP_API +
                                                "/user/sendMoney",
                                            {
                                                userId: id,
                                                cost: setSendFormSum,
                                            },
                                            {
                                                headers: {
                                                    Authorization:
                                                        "Bearer " +
                                                        localStorage.getItem(
                                                            "accessToken",
                                                        ),
                                                },
                                            },
                                        )
                                        .then((response) => {})
                                        .catch((error) => {});
                                }}
                                className="btn"
                            >
                                {t("send")}
                            </a>
                        </nav>
                    </div>
                </div>
            </div>
            <div
                onClick={() => {
                    setFinishRegistrationOpen(false);
                }}
                className={
                    finishRegistrationOpen
                        ? "login-popup popup--fs open"
                        : "login-popup popup--fs"
                }
            >
                <div
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                    className="login-popup__form"
                >
                    <svg
                        className="close-svg"
                        onClick={() => {
                            setFinishRegistrationOpen(false);
                        }}
                        style={{ top: 42 }}
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M17 7L7 17M7 7L17 17"
                            stroke="white"
                            stroke-opacity="0.8"
                            strokeWidth="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                    <h1 style={{ fontSize: 22 }}>Завершите регистрацию</h1>
                    <div>
                        <nav>
                            <p>Электронная почта</p>
                            <input
                                value={finishRegData.email}
                                onChange={(e) => {
                                    setFinishRegData({
                                        ...finishRegData,
                                        email: e.target.value,
                                    });
                                }}
                                type="text"
                                placeholder="Введите электронную почту"
                            />

                            <p>Номер телефона</p>
                            <input
                                value={finishRegData.phone}
                                onChange={(e) => {
                                    setFinishRegData({
                                        ...finishRegData,
                                        phone: e.target.value,
                                    });
                                }}
                                type="text"
                                placeholder="Введите номер телефона"
                            />

                            <p>Введите новый пароль</p>
                            <label
                                style={{ marginBottom: 12 }}
                                className="password-input"
                            >
                                <input
                                    value={finishRegData.password}
                                    onChange={(e) => {
                                        setFinishRegData({
                                            ...finishRegData,
                                            password: e.target.value,
                                        });
                                    }}
                                    type="password"
                                    placeholder={t("insert_your_password")}
                                    name="old-password"
                                />

                                <a
                                    onClick={(e) => {
                                        if (
                                            e.target.previousSibling.type ==
                                            "password"
                                        )
                                            e.target.previousSibling.type =
                                                "text";
                                        else
                                            e.target.previousSibling.type =
                                                "password";
                                    }}
                                    className="password-input__eye"
                                >
                                    <svg
                                        width={15}
                                        height={15}
                                        viewBox="0 0 15 15"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M6.71434 3.1827C6.96835 3.14514 7.23038 3.125 7.50026 3.125C10.6909 3.125 12.7846 5.94053 13.4879 7.05426C13.5731 7.18906 13.6156 7.25646 13.6395 7.36042C13.6573 7.43849 13.6573 7.56166 13.6394 7.63973C13.6156 7.74369 13.5727 7.81154 13.487 7.94723C13.2996 8.24384 13.0139 8.66069 12.6353 9.11278M4.2027 4.1969C2.85141 5.11356 1.93403 6.38711 1.51319 7.0533C1.42768 7.18867 1.38492 7.25635 1.36109 7.3603C1.34319 7.43837 1.34318 7.56153 1.36107 7.6396C1.38489 7.74355 1.42746 7.81095 1.51258 7.94574C2.21596 9.05948 4.30963 11.875 7.50026 11.875C8.78677 11.875 9.89493 11.4172 10.8055 10.7979M1.87526 1.875L13.1253 13.125M6.17443 6.17417C5.83513 6.51348 5.62526 6.98223 5.62526 7.5C5.62526 8.53553 6.46473 9.375 7.50026 9.375C8.01803 9.375 8.48678 9.16513 8.82609 8.82583"
                                            stroke="#7762CD"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                    <svg
                                        width={15}
                                        height={15}
                                        viewBox="0 0 15 15"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M1.51258 7.94574C1.42746 7.81096 1.3849 7.74357 1.36108 7.63963C1.34318 7.56156 1.34318 7.43844 1.36108 7.36037C1.3849 7.25643 1.42746 7.18904 1.51258 7.05426C2.21596 5.94053 4.30962 3.125 7.50025 3.125C10.6909 3.125 12.7845 5.94053 13.4879 7.05426C13.573 7.18904 13.6156 7.25643 13.6394 7.36037C13.6573 7.43844 13.6573 7.56156 13.6394 7.63963C13.6156 7.74357 13.573 7.81096 13.4879 7.94574C12.7845 9.05947 10.6909 11.875 7.50025 11.875C4.30963 11.875 2.21596 9.05947 1.51258 7.94574Z"
                                            stroke="#7762CD"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M7.50025 9.375C8.53579 9.375 9.37525 8.53553 9.37525 7.5C9.37525 6.46447 8.53579 5.625 7.50025 5.625C6.46472 5.625 5.62525 6.46447 5.62525 7.5C5.62525 8.53553 6.46472 9.375 7.50025 9.375Z"
                                            stroke="#7762CD"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </a>
                            </label>

                            <p>Подтвердите пароль</p>
                            <label className="password-input">
                                <input
                                    value={finishRegData.confirm_password}
                                    onChange={(e) => {
                                        setFinishRegData({
                                            ...finishRegData,
                                            confirm_password: e.target.value,
                                        });
                                    }}
                                    type="password"
                                    placeholder={t("insert_your_password")}
                                    name="old-password"
                                />

                                <a
                                    onClick={(e) => {
                                        if (
                                            e.target.previousSibling.type ==
                                            "password"
                                        )
                                            e.target.previousSibling.type =
                                                "text";
                                        else
                                            e.target.previousSibling.type =
                                                "password";
                                    }}
                                    className="password-input__eye"
                                >
                                    <svg
                                        width={15}
                                        height={15}
                                        viewBox="0 0 15 15"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M6.71434 3.1827C6.96835 3.14514 7.23038 3.125 7.50026 3.125C10.6909 3.125 12.7846 5.94053 13.4879 7.05426C13.5731 7.18906 13.6156 7.25646 13.6395 7.36042C13.6573 7.43849 13.6573 7.56166 13.6394 7.63973C13.6156 7.74369 13.5727 7.81154 13.487 7.94723C13.2996 8.24384 13.0139 8.66069 12.6353 9.11278M4.2027 4.1969C2.85141 5.11356 1.93403 6.38711 1.51319 7.0533C1.42768 7.18867 1.38492 7.25635 1.36109 7.3603C1.34319 7.43837 1.34318 7.56153 1.36107 7.6396C1.38489 7.74355 1.42746 7.81095 1.51258 7.94574C2.21596 9.05948 4.30963 11.875 7.50026 11.875C8.78677 11.875 9.89493 11.4172 10.8055 10.7979M1.87526 1.875L13.1253 13.125M6.17443 6.17417C5.83513 6.51348 5.62526 6.98223 5.62526 7.5C5.62526 8.53553 6.46473 9.375 7.50026 9.375C8.01803 9.375 8.48678 9.16513 8.82609 8.82583"
                                            stroke="#7762CD"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                    <svg
                                        width={15}
                                        height={15}
                                        viewBox="0 0 15 15"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M1.51258 7.94574C1.42746 7.81096 1.3849 7.74357 1.36108 7.63963C1.34318 7.56156 1.34318 7.43844 1.36108 7.36037C1.3849 7.25643 1.42746 7.18904 1.51258 7.05426C2.21596 5.94053 4.30962 3.125 7.50025 3.125C10.6909 3.125 12.7845 5.94053 13.4879 7.05426C13.573 7.18904 13.6156 7.25643 13.6394 7.36037C13.6573 7.43844 13.6573 7.56156 13.6394 7.63963C13.6156 7.74357 13.573 7.81096 13.4879 7.94574C12.7845 9.05947 10.6909 11.875 7.50025 11.875C4.30963 11.875 2.21596 9.05947 1.51258 7.94574Z"
                                            stroke="#7762CD"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M7.50025 9.375C8.53579 9.375 9.37525 8.53553 9.37525 7.5C9.37525 6.46447 8.53579 5.625 7.50025 5.625C6.46472 5.625 5.62525 6.46447 5.62525 7.5C5.62525 8.53553 6.46472 9.375 7.50025 9.375Z"
                                            stroke="#7762CD"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </a>
                            </label>
                            <a
                                onClick={() => {
                                    if (finishRegData.email.length < 5) {
                                        toast.error(
                                            "Введите корректный адрес электронной почты!",
                                        );
                                        return;
                                    }
                                    if (finishRegData.phone.length < 9) {
                                        toast.error(
                                            "Введите корректный номер телефона!",
                                        );
                                        return;
                                    }
                                    if (
                                        finishRegData.password !==
                                        finishRegData.confirm_password
                                    ) {
                                        toast.error("Пароли не совпадают!");
                                        return;
                                    }
                                    if (finishRegData.password.length < 5) {
                                        toast.error("Пароль слишком короткий");
                                        return;
                                    }

                                    axios
                                        .post(
                                            process.env.REACT_APP_API +
                                                "/auth/finish-registration",
                                            {
                                                email: finishRegData.email,
                                                phone: finishRegData.phone,
                                                password:
                                                    finishRegData.password,
                                            },
                                            {
                                                headers: {
                                                    Authorization:
                                                        "Bearer " +
                                                        localStorage.getItem(
                                                            "accessToken",
                                                        ),
                                                },
                                            },
                                        )
                                        .then(() => {
                                            setFinishRegistrationOpen(false);
                                            setNeedfill(false);

                                            toast.success(
                                                "Регистрация завершена!",
                                            );
                                        })
                                        .catch((err) => {
                                            if (err.response.status == 409) {
                                                toast.error(
                                                    "Введеная электронная почта уже используется!",
                                                );
                                                return;
                                            }
                                            toast.error(
                                                "Произошла ошибка при отправке данных!",
                                            );
                                        });
                                }}
                                className="btn"
                            >
                                Завершить регистрацию
                            </a>
                        </nav>
                    </div>
                </div>
            </div>
            <div
                onClick={() => {
                    setLogInOpen(false);
                }}
                className={
                    logInOpen
                        ? "login-popup popup--fs open"
                        : "login-popup popup--fs"
                }
            >
                <div
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                    className="login-popup__form"
                >
                    <svg
                        className="close-svg"
                        onClick={() => {
                            setLogInOpen(false);
                        }}
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M17 7L7 17M7 7L17 17"
                            stroke="white"
                            stroke-opacity="0.8"
                            strokeWidth="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                    <h1
                        style={
                            page == 5
                                ? { fontSize: "20px", marginTop: "8px" }
                                : {}
                        }
                    >
                        {page == 5
                            ? t("password_recovery")
                            : page == 6
                              ? t("register")
                              : logInOpen == "reg"
                                ? t("register")
                                : t("log_in")}
                    </h1>
                    {page == 1 ? (
                        <div>
                            <nav>
                                <p>
                                    {" "}
                                    {t("email")} / {t("phone")}
                                </p>

                                <IMaskInput
                                    type="text"
                                    mask={maskOptions}
                                    onAccept={(value, mask) =>
                                        setLoginFormLogin(value)
                                    }
                                    placeholder={t("insert_email_or_phone")}
                                ></IMaskInput>

                                <a onClick={loginApiCall} className="btn">
                                    {t("next")}
                                </a>
                                {false && (
                                    <a
                                        onClick={() => {}}
                                        className="telegram-log"
                                    >
                                        Log in with{" "}
                                        <svg
                                            width="24px"
                                            height="24px"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M23.1117 4.49449C23.4296 2.94472 21.9074 1.65683 20.4317 2.227L2.3425 9.21601C0.694517 9.85273 0.621087 12.1572 2.22518 12.8975L6.1645 14.7157L8.03849 21.2746C8.13583 21.6153 8.40618 21.8791 8.74917 21.968C9.09216 22.0568 9.45658 21.9576 9.70712 21.707L12.5938 18.8203L16.6375 21.8531C17.8113 22.7334 19.5019 22.0922 19.7967 20.6549L23.1117 4.49449ZM3.0633 11.0816L21.1525 4.0926L17.8375 20.2531L13.1 16.6999C12.7019 16.4013 12.1448 16.4409 11.7929 16.7928L10.5565 18.0292L10.928 15.9861L18.2071 8.70703C18.5614 8.35278 18.5988 7.79106 18.2947 7.39293C17.9906 6.99479 17.4389 6.88312 17.0039 7.13168L6.95124 12.876L3.0633 11.0816ZM8.17695 14.4791L8.78333 16.6015L9.01614 15.321C9.05253 15.1209 9.14908 14.9366 9.29291 14.7928L11.5128 12.573L8.17695 14.4791Z"
                                                fill="#fff"
                                            />
                                        </svg>
                                        Telegram
                                    </a>
                                )}
                            </nav>
                        </div>
                    ) : page == 2 ? (
                        <div>
                            <nav>
                                <p> {t("password")}</p>
                                <label className="password-input">
                                    <input
                                        value={loginFormPassword}
                                        onChange={(e) => {
                                            setLoginFormPassword(
                                                e.target.value,
                                            );
                                        }}
                                        type="password"
                                        placeholder={t("insert_your_password")}
                                        name="old-password"
                                    />

                                    <a
                                        onClick={(e) => {
                                            if (
                                                e.target.previousSibling.type ==
                                                "password"
                                            )
                                                e.target.previousSibling.type =
                                                    "text";
                                            else
                                                e.target.previousSibling.type =
                                                    "password";
                                        }}
                                        className="password-input__eye"
                                    >
                                        <svg
                                            width={15}
                                            height={15}
                                            viewBox="0 0 15 15"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M6.71434 3.1827C6.96835 3.14514 7.23038 3.125 7.50026 3.125C10.6909 3.125 12.7846 5.94053 13.4879 7.05426C13.5731 7.18906 13.6156 7.25646 13.6395 7.36042C13.6573 7.43849 13.6573 7.56166 13.6394 7.63973C13.6156 7.74369 13.5727 7.81154 13.487 7.94723C13.2996 8.24384 13.0139 8.66069 12.6353 9.11278M4.2027 4.1969C2.85141 5.11356 1.93403 6.38711 1.51319 7.0533C1.42768 7.18867 1.38492 7.25635 1.36109 7.3603C1.34319 7.43837 1.34318 7.56153 1.36107 7.6396C1.38489 7.74355 1.42746 7.81095 1.51258 7.94574C2.21596 9.05948 4.30963 11.875 7.50026 11.875C8.78677 11.875 9.89493 11.4172 10.8055 10.7979M1.87526 1.875L13.1253 13.125M6.17443 6.17417C5.83513 6.51348 5.62526 6.98223 5.62526 7.5C5.62526 8.53553 6.46473 9.375 7.50026 9.375C8.01803 9.375 8.48678 9.16513 8.82609 8.82583"
                                                stroke="#7762CD"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                        <svg
                                            width={15}
                                            height={15}
                                            viewBox="0 0 15 15"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M1.51258 7.94574C1.42746 7.81096 1.3849 7.74357 1.36108 7.63963C1.34318 7.56156 1.34318 7.43844 1.36108 7.36037C1.3849 7.25643 1.42746 7.18904 1.51258 7.05426C2.21596 5.94053 4.30962 3.125 7.50025 3.125C10.6909 3.125 12.7845 5.94053 13.4879 7.05426C13.573 7.18904 13.6156 7.25643 13.6394 7.36037C13.6573 7.43844 13.6573 7.56156 13.6394 7.63963C13.6156 7.74357 13.573 7.81096 13.4879 7.94574C12.7845 9.05947 10.6909 11.875 7.50025 11.875C4.30963 11.875 2.21596 9.05947 1.51258 7.94574Z"
                                                stroke="#7762CD"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                            <path
                                                d="M7.50025 9.375C8.53579 9.375 9.37525 8.53553 9.37525 7.5C9.37525 6.46447 8.53579 5.625 7.50025 5.625C6.46472 5.625 5.62525 6.46447 5.62525 7.5C5.62525 8.53553 6.46472 9.375 7.50025 9.375Z"
                                                stroke="#7762CD"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                    </a>
                                </label>
                                <a onClick={SendCode} className="forgot">
                                    {t("forgot_password")}
                                </a>
                                <a onClick={loginApiCall} className="btn">
                                    {t("log_in")}
                                </a>
                            </nav>
                        </div>
                    ) : page == 3 ? (
                        <div>
                            <nav>
                                <p> {t("confirmation_code")}</p>
                                <input
                                    value={code}
                                    onChange={(e) => {
                                        setCode(e.target.value);
                                    }}
                                    type="number"
                                    placeholder="000000"
                                    name="code"
                                />
                                <a onClick={codeApiCall} className="btn">
                                    {t("send")}
                                </a>
                            </nav>{" "}
                        </div>
                    ) : page == 4 ? (
                        <>
                            <div>
                                <nav>
                                    <p>{t("email_to_recovery")}</p>
                                    <IMaskInput
                                        mask={{
                                            mask: /^\S*@?\S*$/,
                                        }}
                                        placeholder={t("insert_email")}
                                        onAccept={(value, mask) =>
                                            setRestoreEmail(value)
                                        }
                                        type="text"
                                    />
                                    <a onClick={SendCode} className="btn">
                                        {t("next")}
                                    </a>
                                </nav>
                            </div>
                        </>
                    ) : page == 5 ? (
                        <>
                            <div>
                                <nav>
                                    <p> {t("new_password")}</p>
                                    <label className="password-input">
                                        <input
                                            value={restorePassword}
                                            onChange={(e) => {
                                                setRestorePassword(
                                                    e.target.value,
                                                );
                                            }}
                                            type="password"
                                            placeholder={t(
                                                "insert_your_password",
                                            )}
                                            name="old-password"
                                        />

                                        <a
                                            onClick={(e) => {
                                                if (
                                                    e.target.previousSibling
                                                        .type == "password"
                                                )
                                                    e.target.previousSibling.type =
                                                        "text";
                                                else
                                                    e.target.previousSibling.type =
                                                        "password";
                                            }}
                                            className="password-input__eye"
                                        >
                                            <svg
                                                width={15}
                                                height={15}
                                                viewBox="0 0 15 15"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M6.71434 3.1827C6.96835 3.14514 7.23038 3.125 7.50026 3.125C10.6909 3.125 12.7846 5.94053 13.4879 7.05426C13.5731 7.18906 13.6156 7.25646 13.6395 7.36042C13.6573 7.43849 13.6573 7.56166 13.6394 7.63973C13.6156 7.74369 13.5727 7.81154 13.487 7.94723C13.2996 8.24384 13.0139 8.66069 12.6353 9.11278M4.2027 4.1969C2.85141 5.11356 1.93403 6.38711 1.51319 7.0533C1.42768 7.18867 1.38492 7.25635 1.36109 7.3603C1.34319 7.43837 1.34318 7.56153 1.36107 7.6396C1.38489 7.74355 1.42746 7.81095 1.51258 7.94574C2.21596 9.05948 4.30963 11.875 7.50026 11.875C8.78677 11.875 9.89493 11.4172 10.8055 10.7979M1.87526 1.875L13.1253 13.125M6.17443 6.17417C5.83513 6.51348 5.62526 6.98223 5.62526 7.5C5.62526 8.53553 6.46473 9.375 7.50026 9.375C8.01803 9.375 8.48678 9.16513 8.82609 8.82583"
                                                    stroke="#7762CD"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                            <svg
                                                width={15}
                                                height={15}
                                                viewBox="0 0 15 15"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M1.51258 7.94574C1.42746 7.81096 1.3849 7.74357 1.36108 7.63963C1.34318 7.56156 1.34318 7.43844 1.36108 7.36037C1.3849 7.25643 1.42746 7.18904 1.51258 7.05426C2.21596 5.94053 4.30962 3.125 7.50025 3.125C10.6909 3.125 12.7845 5.94053 13.4879 7.05426C13.573 7.18904 13.6156 7.25643 13.6394 7.36037C13.6573 7.43844 13.6573 7.56156 13.6394 7.63963C13.6156 7.74357 13.573 7.81096 13.4879 7.94574C12.7845 9.05947 10.6909 11.875 7.50025 11.875C4.30963 11.875 2.21596 9.05947 1.51258 7.94574Z"
                                                    stroke="#7762CD"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                                <path
                                                    d="M7.50025 9.375C8.53579 9.375 9.37525 8.53553 9.37525 7.5C9.37525 6.46447 8.53579 5.625 7.50025 5.625C6.46472 5.625 5.62525 6.46447 5.62525 7.5C5.62525 8.53553 6.46472 9.375 7.50025 9.375Z"
                                                    stroke="#7762CD"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                        </a>
                                    </label>
                                    <a
                                        onClick={SendNewPassword}
                                        className="btn"
                                    >
                                        {t("change")}
                                    </a>
                                </nav>
                            </div>
                        </>
                    ) : (
                        <>
                            <div>
                                <nav>
                                    <p> {t("password")}</p>
                                    <label className="password-input">
                                        <input
                                            value={loginRegInput}
                                            onChange={(e) => {
                                                setLoginRegInput(
                                                    e.target.value,
                                                );
                                            }}
                                            type="password"
                                            placeholder={t(
                                                "insert_your_password",
                                            )}
                                            name="old-password"
                                        />

                                        <a
                                            onClick={(e) => {
                                                if (
                                                    e.target.previousSibling
                                                        .type == "password"
                                                )
                                                    e.target.previousSibling.type =
                                                        "text";
                                                else
                                                    e.target.previousSibling.type =
                                                        "password";
                                            }}
                                            className="password-input__eye"
                                        >
                                            <svg
                                                width={15}
                                                height={15}
                                                viewBox="0 0 15 15"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M6.71434 3.1827C6.96835 3.14514 7.23038 3.125 7.50026 3.125C10.6909 3.125 12.7846 5.94053 13.4879 7.05426C13.5731 7.18906 13.6156 7.25646 13.6395 7.36042C13.6573 7.43849 13.6573 7.56166 13.6394 7.63973C13.6156 7.74369 13.5727 7.81154 13.487 7.94723C13.2996 8.24384 13.0139 8.66069 12.6353 9.11278M4.2027 4.1969C2.85141 5.11356 1.93403 6.38711 1.51319 7.0533C1.42768 7.18867 1.38492 7.25635 1.36109 7.3603C1.34319 7.43837 1.34318 7.56153 1.36107 7.6396C1.38489 7.74355 1.42746 7.81095 1.51258 7.94574C2.21596 9.05948 4.30963 11.875 7.50026 11.875C8.78677 11.875 9.89493 11.4172 10.8055 10.7979M1.87526 1.875L13.1253 13.125M6.17443 6.17417C5.83513 6.51348 5.62526 6.98223 5.62526 7.5C5.62526 8.53553 6.46473 9.375 7.50026 9.375C8.01803 9.375 8.48678 9.16513 8.82609 8.82583"
                                                    stroke="#7762CD"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                            <svg
                                                width={15}
                                                height={15}
                                                viewBox="0 0 15 15"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M1.51258 7.94574C1.42746 7.81096 1.3849 7.74357 1.36108 7.63963C1.34318 7.56156 1.34318 7.43844 1.36108 7.36037C1.3849 7.25643 1.42746 7.18904 1.51258 7.05426C2.21596 5.94053 4.30962 3.125 7.50025 3.125C10.6909 3.125 12.7845 5.94053 13.4879 7.05426C13.573 7.18904 13.6156 7.25643 13.6394 7.36037C13.6573 7.43844 13.6573 7.56156 13.6394 7.63963C13.6156 7.74357 13.573 7.81096 13.4879 7.94574C12.7845 9.05947 10.6909 11.875 7.50025 11.875C4.30963 11.875 2.21596 9.05947 1.51258 7.94574Z"
                                                    stroke="#7762CD"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                                <path
                                                    d="M7.50025 9.375C8.53579 9.375 9.37525 8.53553 9.37525 7.5C9.37525 6.46447 8.53579 5.625 7.50025 5.625C6.46472 5.625 5.62525 6.46447 5.62525 7.5C5.62525 8.53553 6.46472 9.375 7.50025 9.375Z"
                                                    stroke="#7762CD"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                        </a>
                                    </label>
                                    <p> {t("confirm_password")}</p>
                                    <label className="password-input">
                                        <input
                                            value={loginRegConfirmInput}
                                            onChange={(e) => {
                                                setLoginRegConfirmInput(
                                                    e.target.value,
                                                );
                                            }}
                                            type="password"
                                            placeholder={t(
                                                "insert_your_password",
                                            )}
                                            name="old-password"
                                        />

                                        <a
                                            onClick={(e) => {
                                                if (
                                                    e.target.previousSibling
                                                        .type == "password"
                                                )
                                                    e.target.previousSibling.type =
                                                        "text";
                                                else
                                                    e.target.previousSibling.type =
                                                        "password";
                                            }}
                                            className="password-input__eye"
                                        >
                                            <svg
                                                width={15}
                                                height={15}
                                                viewBox="0 0 15 15"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M6.71434 3.1827C6.96835 3.14514 7.23038 3.125 7.50026 3.125C10.6909 3.125 12.7846 5.94053 13.4879 7.05426C13.5731 7.18906 13.6156 7.25646 13.6395 7.36042C13.6573 7.43849 13.6573 7.56166 13.6394 7.63973C13.6156 7.74369 13.5727 7.81154 13.487 7.94723C13.2996 8.24384 13.0139 8.66069 12.6353 9.11278M4.2027 4.1969C2.85141 5.11356 1.93403 6.38711 1.51319 7.0533C1.42768 7.18867 1.38492 7.25635 1.36109 7.3603C1.34319 7.43837 1.34318 7.56153 1.36107 7.6396C1.38489 7.74355 1.42746 7.81095 1.51258 7.94574C2.21596 9.05948 4.30963 11.875 7.50026 11.875C8.78677 11.875 9.89493 11.4172 10.8055 10.7979M1.87526 1.875L13.1253 13.125M6.17443 6.17417C5.83513 6.51348 5.62526 6.98223 5.62526 7.5C5.62526 8.53553 6.46473 9.375 7.50026 9.375C8.01803 9.375 8.48678 9.16513 8.82609 8.82583"
                                                    stroke="#7762CD"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                            <svg
                                                width={15}
                                                height={15}
                                                viewBox="0 0 15 15"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M1.51258 7.94574C1.42746 7.81096 1.3849 7.74357 1.36108 7.63963C1.34318 7.56156 1.34318 7.43844 1.36108 7.36037C1.3849 7.25643 1.42746 7.18904 1.51258 7.05426C2.21596 5.94053 4.30962 3.125 7.50025 3.125C10.6909 3.125 12.7845 5.94053 13.4879 7.05426C13.573 7.18904 13.6156 7.25643 13.6394 7.36037C13.6573 7.43844 13.6573 7.56156 13.6394 7.63963C13.6156 7.74357 13.573 7.81096 13.4879 7.94574C12.7845 9.05947 10.6909 11.875 7.50025 11.875C4.30963 11.875 2.21596 9.05947 1.51258 7.94574Z"
                                                    stroke="#7762CD"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                                <path
                                                    d="M7.50025 9.375C8.53579 9.375 9.37525 8.53553 9.37525 7.5C9.37525 6.46447 8.53579 5.625 7.50025 5.625C6.46472 5.625 5.62525 6.46447 5.62525 7.5C5.62525 8.53553 6.46472 9.375 7.50025 9.375Z"
                                                    stroke="#7762CD"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                        </a>
                                    </label>
                                    <a onClick={regApiCall} className="btn">
                                        {t("register")}
                                    </a>
                                </nav>
                            </div>
                        </>
                    )}
                </div>
            </div>
            <div
                onClick={() => {
                    setRegisterOpen(false);
                }}
                className={
                    registerOpen
                        ? "register-popup popup--fs open"
                        : "register-popup popup--fs"
                }
            >
                <div
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                    className="register-popup__form"
                >
                    <svg
                        className="close-svg"
                        onClick={() => {
                            setRegisterOpen(false);
                        }}
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M17 7L7 17M7 7L17 17"
                            stroke="white"
                            stroke-opacity="0.8"
                            strokeWidth="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                    <h1>Регистрация</h1>

                    <div>
                        <nav>
                            <p>Регистрация через:</p>
                            <div className="selector">
                                <div
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.target.parentElement.classList.toggle(
                                            "open",
                                        );
                                    }}
                                    className="selector__header"
                                >
                                    <p className="selector__value">{regType}</p>
                                    <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M6 9L12 15L18 9"
                                            stroke="#7762CD"
                                            strokeWidth="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                    </svg>
                                </div>
                                <div
                                    onClick={(e) => {
                                        if (
                                            e.target.classList.contains(
                                                "selector__body",
                                            )
                                        )
                                            return;
                                        //   e.target.parentElement.previousSibling.querySelector('p').innerHTML = e.target.innerHTML;
                                        e.target.parentElement.parentElement.classList.remove(
                                            "open",
                                        );
                                        setRegType(e.target.textContent);
                                    }}
                                    className="selector__body"
                                >
                                    <div>{t("phone")}</div>
                                    <div>{t("email")}</div>
                                </div>
                            </div>
                            {regType === t("phone") ? (
                                <>
                                    <p>{t("phone")}</p>
                                    <input
                                        type="text"
                                        value={registerMethodData}
                                        onChange={(e) => {
                                            setRegisterMethodData(
                                                e.target.value,
                                            );
                                        }}
                                        placeholder="+79999999999"
                                    />
                                </>
                            ) : (
                                <>
                                    <p>{t("email")}</p>
                                    <input
                                        type="text"
                                        value={registerMethodData}
                                        onChange={(e) => {
                                            setRegisterMethodData(
                                                e.target.value,
                                            );
                                        }}
                                        placeholder={t("your_email")}
                                    />
                                </>
                            )}
                            <p>Пароль</p>
                            <label className="password-input">
                                <input
                                    value={registerFormPassword}
                                    onChange={(e) => {
                                        setRegisterFormPassword(e.target.value);
                                    }}
                                    type="password"
                                    placeholder="Введите пароль"
                                    name="old-password"
                                />
                                <a
                                    onClick={(e) => {
                                        if (
                                            e.target.previousSibling.type ==
                                            "password"
                                        )
                                            e.target.previousSibling.type =
                                                "text";
                                        else
                                            e.target.previousSibling.type =
                                                "password";
                                    }}
                                    className="password-input__eye"
                                >
                                    <svg
                                        width={15}
                                        height={15}
                                        viewBox="0 0 15 15"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M6.71434 3.1827C6.96835 3.14514 7.23038 3.125 7.50026 3.125C10.6909 3.125 12.7846 5.94053 13.4879 7.05426C13.5731 7.18906 13.6156 7.25646 13.6395 7.36042C13.6573 7.43849 13.6573 7.56166 13.6394 7.63973C13.6156 7.74369 13.5727 7.81154 13.487 7.94723C13.2996 8.24384 13.0139 8.66069 12.6353 9.11278M4.2027 4.1969C2.85141 5.11356 1.93403 6.38711 1.51319 7.0533C1.42768 7.18867 1.38492 7.25635 1.36109 7.3603C1.34319 7.43837 1.34318 7.56153 1.36107 7.6396C1.38489 7.74355 1.42746 7.81095 1.51258 7.94574C2.21596 9.05948 4.30963 11.875 7.50026 11.875C8.78677 11.875 9.89493 11.4172 10.8055 10.7979M1.87526 1.875L13.1253 13.125M6.17443 6.17417C5.83513 6.51348 5.62526 6.98223 5.62526 7.5C5.62526 8.53553 6.46473 9.375 7.50026 9.375C8.01803 9.375 8.48678 9.16513 8.82609 8.82583"
                                            stroke="#7762CD"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                    <svg
                                        width={15}
                                        height={15}
                                        viewBox="0 0 15 15"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M1.51258 7.94574C1.42746 7.81096 1.3849 7.74357 1.36108 7.63963C1.34318 7.56156 1.34318 7.43844 1.36108 7.36037C1.3849 7.25643 1.42746 7.18904 1.51258 7.05426C2.21596 5.94053 4.30962 3.125 7.50025 3.125C10.6909 3.125 12.7845 5.94053 13.4879 7.05426C13.573 7.18904 13.6156 7.25643 13.6394 7.36037C13.6573 7.43844 13.6573 7.56156 13.6394 7.63963C13.6156 7.74357 13.573 7.81096 13.4879 7.94574C12.7845 9.05947 10.6909 11.875 7.50025 11.875C4.30963 11.875 2.21596 9.05947 1.51258 7.94574Z"
                                            stroke="#7762CD"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M7.50025 9.375C8.53579 9.375 9.37525 8.53553 9.37525 7.5C9.37525 6.46447 8.53579 5.625 7.50025 5.625C6.46472 5.625 5.62525 6.46447 5.62525 7.5C5.62525 8.53553 6.46472 9.375 7.50025 9.375Z"
                                            stroke="#7762CD"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </a>
                            </label>
                            <p>Есть промокод?</p>
                            <input
                                type="text"
                                name=""
                                placeholder="Промокод"
                                id=""
                            />
                            <label>
                                <input type="checkbox" name="" id="" />
                                <p>
                                    Согласен с{" "}
                                    <Link
                                        onClick={() => {
                                            setRegisterOpen(false);
                                        }}
                                        to="/terms"
                                    >
                                        {" "}
                                        условиями и положениями
                                    </Link>
                                </p>
                            </label>
                            <a
                                onClick={() => {
                                    registerApiCall();
                                }}
                                className="btn"
                            >
                                {t("register")}
                            </a>
                        </nav>
                    </div>
                </div>
            </div>
        </>
    );
};
export default Header;
